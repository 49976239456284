<nav class="navbar navbar-expand-md navbar-dark">

  <div class="navbar-brand">
    <a class="logo" [routerLink]="['/']"><img src="./assets/images/logo/amjad_ai_logo-white.svg" alt="Logo"></a>
    <a class="company_logo">
      <img [src]="currentUser?.company?.logo" (error)="currentUser.company.logo=''"
        onerror="this.src = './assets/images/defaults/companies_default.svg'"
        [ngStyle]="{'object-fit':currentUser.company.logo ? '' :'scale-down' }" alt="Company Logo"
        [matTooltip]="currentUser.company.name">

      <!-- <span *ngIf="!currentUser.company.logo"> {{currentUser.company.name | titlecase}}</span> -->
    </a>
  </div>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
    aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link p-0" [routerLink]="[ '/panel' ]" [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{exact: true}">{{"home.Home"|translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link p-0" [routerLink]="[ '/panel/offers' ]" [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{exact: true}">{{"home.Offers"|translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link p-0" [routerLink]="[ '/panel/vendors' ]" [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{exact: true}">{{"home.Vendors"|translate}}</a>
      </li>
      <li class="nav-item" *ngIf="currentUser.type != 'family'">
        <a class="nav-link p-0" [routerLink]="[ '/panel/news' ]" [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{exact: true}">{{"home.News"|translate}}</a>
      </li>

      <li class="nav-item" *ngIf="currentUser.type != 'family'">
        <a class="nav-link p-0" [routerLink]="[ '/panel/survey' ]" [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{exact: true}">{{"home.Surveys"|translate}}</a>
      </li>

    </ul>
    <div class="d-flex align-items-center">
      <!-- <a class="notification_icon" (click)="OpenNotificationList()"><svg xmlns="http://www.w3.org/2000/svg" width="17"
          height="20" viewBox="0 0 17 20">
          <path id="notifications"
            d="M161.062-863a1.062,1.062,0,0,1-.757-.287A.939.939,0,0,1,160-864a.939.939,0,0,1,.305-.713,1.062,1.062,0,0,1,.757-.287h1.063v-7a5.638,5.638,0,0,1,1.328-3.687,6.173,6.173,0,0,1,3.453-2.112v-.7a1.4,1.4,0,0,1,.465-1.062A1.587,1.587,0,0,1,168.5-880a1.587,1.587,0,0,1,1.129.438,1.4,1.4,0,0,1,.465,1.063v.7a6.173,6.173,0,0,1,3.453,2.112A5.638,5.638,0,0,1,174.875-872v7h1.063a1.062,1.062,0,0,1,.757.287A.939.939,0,0,1,177-864a.939.939,0,0,1-.305.713,1.062,1.062,0,0,1-.757.287Zm7.438,3a2.112,2.112,0,0,1-1.5-.588,1.871,1.871,0,0,1-.624-1.412h4.25a1.871,1.871,0,0,1-.624,1.412A2.112,2.112,0,0,1,168.5-860Z"
            transform="translate(-160 880)" fill="#fff" />
        </svg>
        <span *ngIf="totalUnreadNotifications">{{totalUnreadNotifications}}</span>
      </a> -->

      <div class="notification_Icon dropdown-container" id="userAccountDrop" appDropDown>
        <div class="dropdown-toggler d-flex align-items-center" (click)="notificationList_opened=true">
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20">
            <path id="notifications"
              d="M161.062-863a1.062,1.062,0,0,1-.757-.287A.939.939,0,0,1,160-864a.939.939,0,0,1,.305-.713,1.062,1.062,0,0,1,.757-.287h1.063v-7a5.638,5.638,0,0,1,1.328-3.687,6.173,6.173,0,0,1,3.453-2.112v-.7a1.4,1.4,0,0,1,.465-1.062A1.587,1.587,0,0,1,168.5-880a1.587,1.587,0,0,1,1.129.438,1.4,1.4,0,0,1,.465,1.063v.7a6.173,6.173,0,0,1,3.453,2.112A5.638,5.638,0,0,1,174.875-872v7h1.063a1.062,1.062,0,0,1,.757.287A.939.939,0,0,1,177-864a.939.939,0,0,1-.305.713,1.062,1.062,0,0,1-.757.287Zm7.438,3a2.112,2.112,0,0,1-1.5-.588,1.871,1.871,0,0,1-.624-1.412h4.25a1.871,1.871,0,0,1-.624,1.412A2.112,2.112,0,0,1,168.5-860Z"
              transform="translate(-160 880)" fill="#fff" />
          </svg>
          <span class="num">{{totalUnreadNotifications>99 ?'99+':totalUnreadNotifications}}</span>

        </div>


        <div class="custom-dropdown-menu dropdown-inner-menu">
          @if (notificationList_opened) {
          <app-notification-list (Nots_Readed)="totalUnreadNotifications=0"
            (Closed)="notificationList_opened=false"></app-notification-list>
          }
        </div>
        <div class="overlayLayer" (click)="notificationList_opened=false"></div>
      </div>

      <div class="userAccount dropdown-container m-0" id="userAccountDrop" appDropDown>
        @if (currentUser) {
        <div class="dropdown-toggler d-flex align-items-center" (click)="listOpened= !listOpened">
          <div class="profile-image">
            <!-- <img src="/assets/images/image.png" alt="user image" class=" dropdown-toggler"> -->
            <img *ngIf="currentUser.image" [src]="currentUser.image" (error)="currentUser.image = ''" />
            <span *ngIf="!currentUser.image" [innerHTML]="currentUser.full_name | shortName"></span>
          </div>
        </div>
        }

        <div class="custom-dropdown-menu dropdown-inner-menu">
          <ul>
            <li>
              <!-- <a  (click)="listOpened= !listOpened"> -->
              <a (click)="listOpened= !listOpened" [routerLink]="['/panel/profile']">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                  <path id="person_FILL1_wght400_GRAD0_opsz24"
                    d="M169-791a4.336,4.336,0,0,1-3.178-1.322A4.334,4.334,0,0,1,164.5-795.5a4.334,4.334,0,0,1,1.322-3.178A4.336,4.336,0,0,1,169-800a4.336,4.336,0,0,1,3.178,1.322A4.334,4.334,0,0,1,173.5-795.5a4.334,4.334,0,0,1-1.322,3.178A4.336,4.336,0,0,1,169-791Zm-9,6.75v-.9a3.3,3.3,0,0,1,.492-1.758,3.28,3.28,0,0,1,1.308-1.223,16.731,16.731,0,0,1,3.544-1.308,15.421,15.421,0,0,1,3.656-.436,15.421,15.421,0,0,1,3.656.436,16.731,16.731,0,0,1,3.544,1.308,3.279,3.279,0,0,1,1.308,1.223A3.3,3.3,0,0,1,178-785.15v.9a2.164,2.164,0,0,1-.661,1.589,2.164,2.164,0,0,1-1.589.661h-13.5a2.164,2.164,0,0,1-1.589-.661A2.164,2.164,0,0,1,160-784.25Z"
                    transform="translate(-160 800)" fill="#3eb6b6" />
                </svg>
                {{ 'general.Manage my Profile' | translate }}
              </a>
            </li>
            <li>
              <a (click)="logout()">
                <svg id="Group_12578" data-name="Group 12578" xmlns="http://www.w3.org/2000/svg" width="17.791"
                  height="18" viewBox="0 0 17.791 18">
                  <path id="Path_87486" data-name="Path 87486"
                    d="M5,5h6a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2h6a1,1,0,0,0,1-1h0a1,1,0,0,0-1-1H5Z"
                    transform="translate(-3 -3)" fill="#b3b3b3" />
                  <path id="Path_87487" data-name="Path 87487"
                    d="M20.65,11.65,17.86,8.86a.5.5,0,0,0-.86.35V11H10a1,1,0,0,0-1,1H9a1,1,0,0,0,1,1h7v1.79a.5.5,0,0,0,.85.35l2.79-2.79A.492.492,0,0,0,20.65,11.65Z"
                    transform="translate(-3 -3)" fill="#b3b3b3" />
                </svg>
                {{ 'general.Logout' | translate }}
              </a>
            </li>
          </ul>
        </div>
      </div>


      <a class="lang" (click)="changeLanguage()">{{"general.EN"|translate}}</a>
    </div>

  </div>
</nav>