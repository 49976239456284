export class DeviceRedirectService {
    private googlePlayLink = 'https://play.google.com/store/apps/details?id=sa.com.expertapps.walla';
    private appleStoreLink = 'https://apps.apple.com/us/app/offerx-1/id6503241299?uo=4';

    redirectToStore(): void {
        const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

        if (/android/i.test(userAgent)) {
            // Redirect to Google Play
            window.location.href = this.googlePlayLink;
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
            // Redirect to Apple App Store
            window.location.href = this.appleStoreLink;
        } else {
            console.log('Device not recognized as Android or iOS.');
            // Optional: Handle other platforms here
        }
    }
}