import { Component } from '@angular/core';
import { DeviceRedirectService } from './services/deviceRedirection.service';

@Component({
  selector: 'app-redirection-to-mobiles',
  templateUrl: './redirection-to-mobiles.component.html',
  styles: ``
})
export class RedirectionToMobilesComponent {
  constructor(private deviceRedirectService: DeviceRedirectService) { }

  ngOnInit(): void {
    this.deviceRedirectService.redirectToStore();
  }
}
