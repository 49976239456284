import { Component } from '@angular/core';
import { VendorRegisterComponent } from '../../../shared/vendor-register/vendor-register.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../../../services/authentication.service';
import { LookupsService } from '../../../services/lookups.service';
import { CompanyRegisterComponent } from '../../../shared/company-register/company-register.component';

@Component({
  selector: 'app-new-footer',
  templateUrl: './new-footer.component.html',
  styles: ``
})
export class NewFooterComponent {
links: any;
  constructor(
    private dialog: MatDialog,
    private _LookupsService: LookupsService,
    public authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.getAllLinks();
  }

  getAllLinks() {
    this._LookupsService.getAllSocialMedia().subscribe((res: any) => {
      this.links = res.data;
    })
  }

  OnRegisterAsCompany(packageId: any = null) {
    const dialogRef = this.dialog.open(CompanyRegisterComponent, {
      width: '540px',
      data: { packageId }
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
      }
    })
  }

  OnRegisterAsVendor() {
    const dialogRef = this.dialog.open(VendorRegisterComponent, {
      width: '540px'
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
      }
    })
  }
}
