<footer>
    <div class="help">
      <div>
        <img src="./assets/images/logo/amjad_ai_logo-footer.svg" alt="" class="logo">
      </div>
      <ul>
        <li><a [routerLink]="['/contact']">{{"home.Help & Contact"|translate}} </a></li>
        <li><a [routerLink]="['/faq']"> {{"home.Frequently Asked Questions"|translate}}</a></li>
        <li><a [routerLink]="['/terms']"> {{"home.Terms & Conditions"|translate}}</a></li>
        <li><a [routerLink]="['/privacy-policy']"> {{"home.Privacy Policy"|translate}}</a></li>
      </ul>
  
      <div class="btn-container" [hidden]="authService.currentUserValue">
        <button mat-button class="btn" (click)="OnRegisterAsCompany()">{{"homepage.Sign up as a company"|translate}}</button>
        <button mat-button class="btn" (click)="OnRegisterAsVendor()">{{"homepage.Join us as a vendor"|translate}}</button>
      </div>
  
  
      <div class="downloadApp">
        <p class="title">
          {{"home.Download our app"|translate}}
        </p>
        <a href="https://play.google.com/store/apps/details?id=sa.com.expertapps.walla"><img src="/assets/images/google_play.png" alt="image"></a>
        <a href="https://apps.apple.com/us/app/offerx-1/id6503241299?uo=4"><img src="/assets/images/appStore.png" alt="image"></a>
      </div>
    </div>
  
  
    <div class="container">
      <div class="copy_right row">
  
        <div class="col-md-6 col-12 social_links" *ngIf="links?.length">
         <span class="follow"> {{"home.Follow Us"|translate}}</span>
          <span *ngFor="let item of links">
            <a *ngIf="item.value"
          [href]="item.type=='phone_number'?'https://api.whatsapp.com/send?phone=966'+item.value:item.value"
          target="_blank">
          <img [src]="item.image" alt="icon">
          </a>
          </span>
        </div>
    
        <p class="copyright col-md-6 col-12 d-flex align-items-center justify-content-center">
          © {{"general.All rights reserved"|translate}} 2025
        </p>
    
      </div>
    </div>
  </footer>