<div class="main-wrapper basicLayout">
  <img class="cover_img" src="/assets/images/covers/basicLayoutCover.png" alt="Cover">
  <nav>
    <a href="/login"><img src="./assets/images/logo/amjad_ai_logo-white.svg" alt="Amjad Logo"></a>
    <a class="language_btn" (click)="changeLanguage()">
      {{"general.EN"|translate}}
    </a>
  </nav>
  <section>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </section>

  <footer>
    <div class="text-center">
      © {{"general.All rights reserved"|translate}} 2025
    </div>
  </footer>
</div>