import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';
import { ConfigService } from '../../../../helpers/config.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Patterns } from '../../../../helpers/patterns';
import { AuthAPIService } from '../../services/auth-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidator } from '../../../../helpers/custom.validator';
import { AuthenticationService, hexToRgb } from '../../../../services/authentication.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styles: ``
})
export class ResetPasswordComponent implements OnInit {
  hideConfirm: boolean = true;
  hide: boolean = true;
  symbols: string = `(! , @, #, $, %, &, *, +, =, {, }, ?, <, >, ", ')`

  loading: boolean = false;
  Token: string = "";
  phone: string = "";
  constructor(
    private _config: ConfigService,
    private _formBuilder: FormBuilder,
    private _AuthApiService: AuthAPIService,
    private _router: Router,
    private authService: AuthenticationService,
    private _activatedRoute: ActivatedRoute) {
    this.authService.clearUserData();
  }
  formGroup!: FormGroup;
  isSubmitted = false;
  ngOnInit(): void {
    this.formGroup = this._formBuilder.group({
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(Patterns.password),
        Validators.pattern(Patterns.enTxtWithSpecial),
        Validators.maxLength(100)
      ]),
      password_confirmation: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ])
    }
    )

    this._activatedRoute.params.subscribe(params => {
      if (params['id']) {
        this.Token = params['id'];
        this.CheckToken();
      }
      if (params['phone']) {
        this.phone = params['phone'];
      }
    });
    this.confirmPasswordValidation()

  }

  onSend() {
    this.isSubmitted = true;
    if (this.formGroup.invalid) { return; }
    this.loading = true;
    this._AuthApiService.ResetPassword(this.formGroup.value, this.Token).subscribe({
      next: (res) => {
        this.loading = false;
        // this._router.navigate(['/login']);
        this.OpenSuccessMessage();
        let user = res.data.user;
        user.company = res.data.company
        if (user.id == "c7cdfd56-49dc-4be6-a305-145ccf9221db") {
          user.company.color = "#235789"
          user.company.font_color = "#F1D302"
        }
        user.company.color = hexToRgb(user?.company?.color)
        this.authService.currentUserSubject.next(user);
        localStorage.setItem('authUser', JSON.stringify(user));
        localStorage.setItem("token", JSON.stringify(res.data.token));
        if (res.data?.first_login) {
          this._config.goTo(`/interests`)
        } else {
          this._config.goTo("/panel");
          let color = res.data.company?.color
          let text_color = res.data.company?.font_color
          document.documentElement.style.setProperty('--main-color', color);
          document.documentElement.style.setProperty('--main-text-color', text_color);
          console.info(`color:${color} , font_color: ${text_color}`)
        }
      },
      error: (error) => {
        this.loading = false;
      },
    })

  }

  OpenSuccessMessage() {
    // this.dialogRef.close();
    this._config.SusseccMessage(this._config._translate.instant("resetPassword.The Password had been changed"))
  }

  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }


  confirmPasswordValidation() {
    this.formGroup.controls['password_confirmation'].valueChanges.subscribe(data => {
      const pass = this.formGroup.get('password')?.value;
      if (pass !== data && pass.length && data.length) {
        this.formGroup.get('password_confirmation')?.setErrors({ notMatch: true });
      }
    })

    this.formGroup.controls['password'].valueChanges.subscribe(data => {
      const password_confirmation = this.formGroup.get('password_confirmation')?.value;
      if (password_confirmation == data) {
        this.formGroup.get('password_confirmation')?.setErrors(null);
      }
      else if (data.length && password_confirmation?.length && password_confirmation !== data) {
        this.formGroup.get('password_confirmation')?.setErrors({ notMatch: true });
      }
    })

  }


  CheckToken() {
    this._AuthApiService.CheckToken(this.Token).subscribe({
      next: (res: any) => {
      },
      error: (error: any) => {
        this._router.navigate(['/login']);
      },
    })
  }


  get isEnglishOnlyValid() {
    return this.formGroup.controls['password'].value.match(Patterns.enTxtWithSpecial);
  }

  get isMinLengthValid() {
    return this.formGroup.controls['password'].value.length >= 8;
  }

  get isLowerCaseValid() {
    return this.formGroup.controls['password'].value.match(/[a-z]/);
  }

  get isUpperCaseValid() {
    return this.formGroup.controls['password'].value.match(/[A-Z]/);
  }

  get isNumberValid() {
    return this.formGroup.controls['password'].value.match(/\d/);
  }

  get isSymbolValid() {
    return this.formGroup.controls['password'].value.match(/[!@#$%&*+=?{}<>"']/);
  }
}
